@import url(https://cdn.syncfusion.com/ej2/material.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.red-dot {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: #EB5050;
}

.green-dot {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: #39A561;
}

.rebooting {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
}

.detailTable {
    font-family: arial, sans-serif;
  }
  
.detailTableTd {
    text-align: left;
    padding: 8px;
}

.screenshot {
  width: 150px;
  height: 120px;
  cursor: pointer;
}

.reboot {
  background-color: #194C98;
  font-size: 16px;
  color: white;
}

.e-grid .e-headercell {
  background-color: #C5D2E5;
  font-weight: bold;
  font-size: 16px;
  color: #363636;
}

.e-grid .e-headercelldiv {
  font-size: 16px;
  color: #363636;
}

.navbar {
  overflow: hidden;
  background-color: #333;
}

.center {

  width: 300px;
  height: 100px;
  position: absolute;
  top: 42%;
  left: 50%;
  margin: -70px 0 0 -170px;
}

.login {
  padding-top: 40px;
  padding-left: 10px;
}

.image {
  padding-bottom: 100px
}

.username {
  margin-bottom: 25px;
}

 
.e-float-input:not(.e-input-group) .e-float-line::before, .e-float-input:not(.e-input-group) .e-float-line::after, .e-float-input:not(.e-input-group) .e-float-line::before, .e-float-input:not(.e-input-group) .e-float-line::after, .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before, .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after, .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before, .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
  background: #194C98;
}

input.e-input::selection, textarea.e-input::selection, .e-input-group input.e-input::selection, .e-input-group.e-control-wrapper input.e-input::selection, .e-float-input input::selection, .e-float-input.e-control-wrapper input::selection, .e-input-group textarea.e-input::selection, .e-input-group.e-control-wrapper textarea.e-input::selection, .e-float-input textarea::selection, .e-float-input.e-control-wrapper textarea::selection {
  background: #C5D2E5;
  color: #fff;
}

#e-float-text e-label-top {
  color: green;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: #194C98;
}

.login-button {
  background-color: #194C98;
  font-size: 16px;
  color: white;
  margin-left: 210px;
} 

.i {
  margin-bottom: 50px;
  right: 5px;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -32px;
  position: relative;
  z-index: 2;
  font-size: 14px;
  color: #194C98;
  cursor: pointer;
}

.container{
  padding-top:50px;
  margin: auto;

}

.NavbarItems {
  background: #194C98;
  height: 120px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.2rem;
}

.navbar-logo {
  justify-self: start;
  position: absolute;
  left: 28px;
}

.rounded-rectangle {
  border-radius: 25px;
  background: #C5D2E5;
  padding: 6px;
  width: 250px;
  height: 70px;
  position: absolute;
  left: 250px;
}

.error-b {
  position: relative;
  bottom: 15px;
  left: 80px;
  font-weight: bold;
  font-size: 20px;
  color: #363636;
}

.no-error {
    position: relative;
    bottom: 30px;
    left: 66px;
    font-weight: bold;
    font-size: 20px;
    color: #363636;
}

.error-span {
  color: #EB5050
}

.no-error-span {
  color: #39A561
}

.logout {
  position: fixed;
  right: 100px;
  top: 5px;
  cursor: pointer;
}

.logout-text {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.logout-image {
  position: relative;
  top: -44px;
  right: -82px;
}
